import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-device-thumbnail',
  templateUrl: './device-thumbnail.component.html',
  styleUrls: ['./device-thumbnail.component.scss'],
})
export class DeviceThumbnailComponent implements OnChanges {
  @Input() deviceModel?: string;
  @Input() size: number = 100;

  thumbnailSrc = '';

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['deviceModel']?.currentValue !== this.deviceModel ||
      changes['deviceModel']?.isFirstChange()
    ) {
      this.thumbnailSrc = this.getThumbnailSrc();
    }
  }

  private getThumbnailSrc(): string {
    const path = `/assets/images/device-thumbnails`;

    switch (this.deviceModel) {
      default:
        console.warn(
          `DeviceThumbnailComponent - No thumbnail configured for device model "${this.deviceModel}"`
        );

        return `${path}/placeholder.png`;
    }
  }
}
