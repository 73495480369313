import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';

const scopes: string[] = [
  'openid',
  'profile',
  'com.rmio.pit-board/read',
  'com.rmio.pit-board/write',
];

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: environment.oidc.authority,
        postLoginRoute: '/home',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/unauthorized',
        redirectUrl: `${window.location.origin}/login-callback`,
        postLogoutRedirectUri: window.location.origin,
        clientId: environment.oidc.clientId,
        scope: scopes.join(' '),
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        logLevel: LogLevel.Warn,
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
